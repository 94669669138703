<template>
  <!-- <div class="app-container">
    <el-container>
      <el-header class="app-header">
        <div class="app-logo">
          <img class="loginC" src="./assets/zclsLogin.png" alt="企业Logo" />
        </div>
        <div class="app-user">
          <el-dropdown trigger="click" placement="bottom-end">
            <span class="el-dropdown-link">
              <i class="el-icon-user"></i> {{ username }}
            </span>
            <el-dropdown-menu slot="dropdown">
              <el-dropdown-item @click="logout">退出登录</el-dropdown-item>
            </el-dropdown-menu>
          </el-dropdown>
        </div>
      </el-header>
      <el-container>
        <el-aside class="app-sidebar">
          <el-menu ref="myMenu" :default-active="$route.path" background-color="#f0f2f5" text-color="#303133">
            <el-submenu index="/system">
              <template #title>系统管理</template>
              <el-menu-item index="/ksh" @click="$router.push('/ksh')">可视化项目展示</el-menu-item>
              <el-menu-item index="/user" @click="$router.push('/user')">用户管理</el-menu-item>
              <el-menu-item index="/role" @click="$router.push('/role')">角色管理</el-menu-item>
              <el-menu-item index="/permission" @click="$router.push('/permission')">权限管理</el-menu-item>
            </el-submenu>
          </el-menu>
        </el-aside>
        <el-main class="app-content">
          <router-view />
        </el-main>
      </el-container>
      <el-footer class="app-footer">
        © 2022 企业名称 版权所有
      </el-footer>
    </el-container>
  </div>-->
  <!-- /* 间隔======================================================= 下面是可视化*/ -->

  <div class="boy">
    <div class="top">
      <ul class="ulC">
        <li>
          <a href="#">
            <strong class="ce1">智慧学院</strong>
          </a>
        </li>
      </ul>
      <div id="cgxzhsj">
        <p>
          <span>农学与生物技术学院综合数据</span>
        </p>
      </div>
      <div class="suBav">
        <div>
          <ul>
            <li>
              <a href>综合数据</a>
            </li>
            <li>
              <a href>在校生数据</a>
            </li>
            <li>
              <a href>招生数据</a>
            </li>
            <li>
              <a href>就业数据</a>
            </li>
          </ul>
        </div>

        <div>
          <ul>
            <li>
              <a href>教职工数据</a>
            </li>
            <li>
              <a href>科研数据</a>
            </li>
            <li>
              <a href>后勤数据</a>
            </li>
            <li>
              <a href>图书馆数据</a>
            </li>
            <li>
              <a href>能耗数据</a>
            </li>
          </ul>
        </div>
      </div>
    </div>
    <div>
      <img
        :src="`/assets/VisualDisplayImage/u1.png`"
        class="imgone"
        alt="image description"
      />
    </div>

    <div class="sunSt">
      <div style="margin: 0px 0px 0px 5px;">
        <div id="u4_div">
          <div class="u4_divS">
            <!-- 学校概况 -->
            <div class="zyqk">
              <strong>学院概况</strong>
            </div>
            <div
              class="sunStone"
              v-for="(item,index) in StudentData"
              :key="index"
            >
              <div class="sunSt">
                <img
                  :src="`/assets/VisualDisplayImage/${item.icon}.png`"
                  class="imgc"
                  alt
                />
                <span>{{item.name}}</span>
              </div>
              <div class="sunStonemagin">
                <span>{{item.number}} {{item.DW}}</span>
              </div>
            </div>
          </div>
        </div>
        <div id="u4_div">
          <div class="zyqk">
            <strong>专业建设点情况</strong>
          </div>
          <div
            style="height: 50%;width: 100%;"
            ref="zdzy"
          >
            <!-- 专业情况 -->
          </div>
          <div style="height: 50%;width: 100%;display:flex;">
            <div
              style="height: 50%;width: 50%;"
              ref="tszy"
            >
              <!-- 特色专业 -->
            </div>
            <div
              style="height: 50%;width: 50%;"
              ref="jpkc"
            >
              <!-- 精品课程 -->
            </div>
          </div>
        </div>
        <div
          id="u4_div"
          style="margin-bottom: 5px;"
        >
          <!-- 教学装备室 -->
          <div class="zyqk">
            <strong>教学装备室</strong>
          </div>
          <div class="zyqkc">
            装备室总数（间）：
            <span>{{zbsSum}}</span>
          </div>
          <div class="zbsSu">
            <div
              class="zbsStyle"
              v-for="(item,index) in ZBSData"
              :key="index"
            >
              <img
                src="/assets/JXZBS/d1.png"
                width="50px"
                height="34px"
              />
              <div style="margin-top: 4px;">
                <span>{{item.name}}</span>
              </div>
              <div style="margin-top:10px">
                <span>{{item.value}}间</span>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div>
        <div id="u0_div">
          <!-- Mian -->
          <div style="height: 100%;width: 100%;">
            <div class="zyqk">
              <strong>数据概览</strong>
            </div>
            <div class="mainKK">
              <div
                id="u115_div"
                v-for="(item,index) in MainData"
                :key="index"
              >
                <div id="u116_div">
                  <div class="bcFont">
                    <span>{{item.title}}</span>
                  </div>
                </div>
                <div class="clsSWEE">
                  <div
                    v-for="(itemce,indexce) in item.ListV"
                    :key="indexce"
                  >
                    <span>{{itemce.name}}</span>
                    <br />
                    <br />
                    <span>{{itemce.value}}{{itemce.DW}}</span>
                  </div>
                  <!-- <div>
                    <span>课程总量</span>
                    <br />
                    <br />
                    <span>1567门</span>
                  </div>-->
                </div>
              </div>

              <!-- jies -->
            </div>
            <img
              src="/assets/Mian/main.png"
              class="mainS"
              alt
            />
            <img
              src="/assets/Mian/g1.png"
              style="top: 20%;left: 40%;width: 142px;"
              class="lianxian"
              alt
            />
            <img
              src="/assets/Mian/g2.png"
              style="top: 22%;left: 53%;width: 138px;"
              class="lianxian"
              alt
            />
            <img
              src="/assets/Mian/g3.png"
              style="top: 50%;left: 40.2%;"
              class="lianxian"
              alt
            />
            <img
              src="/assets/Mian/g4.png"
              style="top: 48%;left: 54.2%;width:108px"
              class="lianxian"
              alt
            />
          </div>
        </div>
        <div style="display: flex;">
          <div id="u140_divER">
            <!-- 学生数据 -->
            <div class="zyqk">
              <strong>学生数据</strong>
            </div>
            <div class="zyqkc">
              在校生总数：
              <span>{{formatCurrency(zxsSum)}}人</span>
            </div>
            <div
              class="zbsSu"
              style="margin-top: 8%;"
            >
              <div
                class="zbsStyle"
                v-for="(item,index) in XSSJData"
                :key="index"
              >
                <img
                  :src="`/assets/XSSJ/${item.icon}`"
                  width="70px"
                  height="54px"
                />
                <div style="margin-top: 4px;">
                  <span>{{item.name}}</span>
                </div>
                <div style="margin-top:10px">
                  <span>{{formatCurrency(item.value)}}人</span>
                </div>
              </div>
            </div>
          </div>
          <div id="u140_divER">
            <img
              src="/assets/JYFX/f5.png"
              class="jyfximgS cel"
              alt
            />
            <img
              src="/assets/JYFX/f4.png"
              class="jyfximgS celsmal"
              alt
            />

            <img
              src="/assets/JYFX/f1.png"
              style="top: 169px;left: -75px;"
              class="jyfximgS"
              alt
            />
            <img
              src="/assets/JYFX/f2.png"
              style="top: 182px;left: -110px;"
              class="jyfximgS"
              alt
            />
            <img
              src="/assets/JYFX/f3.png"
              style="top: 187px;left: -129px;"
              class="jyfximgS"
              alt
            />
            <div
              id="JSFXEchart"
              ref="JSFXEchart"
            >
              <!-- 就业分析 -->
            </div>
          </div>
        </div>
      </div>
      <div style="margin: 0px 5px 0px 0px;">
        <div id="u4_div">
          <div
            id="ceshiEchart"
            ref="ceshiEchart"
          >
            <!-- 师资规模 -->
          </div>
        </div>
        <div id="u4_div">
          <div class="zyqk">
            <strong>课程情况</strong>
          </div>
          <img
            class="kcImg"
            src="/assets/KCQK/h1.png"
            alt
          />
          <img
            class="kcImgt"
            src="/assets/KCQK/h2.png"
            alt
          />
          <div class="ceEE">
            <div
              class="kcStyle"
              v-for="(item,index) in CKQKData"
              :key="index"
            >
              <div class="kcStylea">
                <img
                  :src="`/assets/KCQK/${item.icon}.png`"
                  alt
                />
                <span style="margin-left: 10px;">{{item.name}}</span>
              </div>
              <div>{{formatCurrency(item.value)}}</div>
            </div>
          </div>

          <!-- <img class="kcImg" src="/assets/KCQK/h1.png" alt="">
          <img class="kcImgt" src="/assets/KCQK/h2.png" alt="">-->
        </div>
        <div
          id="u4_div"
          style="margin-bottom: 5px;"
        >
          <div class="zyqk">
            <strong>教学成果</strong>
          </div>
          <div class="space-around">
            <div
              class="jxcgStylea"
              v-for="(item,index) in JXCQData"
              :key="index"
            >
              <div style="margin: 0px 0px 18px 0px;">
                <span>
                  <strong>{{item.value}}</strong>
                </span>
              </div>
              <div class="jxcgStyle">
                <img
                  src="/assets/JXCG/c1.png"
                  width="40px"
                  height="37px"
                  alt
                />
                <img
                  src="/assets/JXCG/c2.png"
                  style="margin: 0px 0px -14px 0px;"
                  width="99px"
                  height="30px"
                  alt
                />
                <img
                  src="/assets/JXCG/c3.png"
                  width="100px"
                  height="22px"
                  alt
                />
                <span class="cgStyle">{{item.name}}</span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// export default {
//   name: 'App',

//   data() {
//     return {
//       username: '用户名称',
//       defaultOpened: ['/system'],
//     };
//   },
//   created: {},
//   watch: {
//     $route(to, from) {
//       if (to.path !== from.path) {
//         this.$nextTick(() => {
//           this.$refs.menu.myMenu && this.$refs.menu.setCurrentPath(to.path);
//         });
//       }
//     },
//   },
//   methods: {
//     logout() {
//       // 退出登录逻辑
//     },
//   },
// };

/* 间隔======================================================= 下面是可视化*/

export default {
  name: "VisualDispalayView",
  mounted() {
    document.querySelector("body").style.margin = 0;
    this.setEcharta();
    this.setEchartzyqk();
    //特色专业
    this.setEcharttszy();
    //精品课程
    this.setEchartjpkc();
    //就业分析
    this.JYFXsetEcharta();
  },
  data: function () {
    return {
      chart: "",
      option: "",
      zbsSum: 28,
      zxsSum: 1499,
      StudentData: [
        {
          icon: "a1",
          name: "学院占地面积",
          number: "0",
          DW: "平方米",
        },
        {
          icon: "a2",
          name: "学院建筑面积",
          number: "0",
          DW: "平方米",
        },
        {
          icon: "a3",
          name: "绿化面积",
          number: "0",
          DW: "平方米",
        },
        {
          icon: "a4",
          name: "固定资产",
          number: "0",
          DW: "亿元",
        },
      ],
      CKQKData: [
        {
          name: "课程总数",
          value: "400",
          icon: "b1",
        },
        {
          name: "国家级课程",
          value: "0",
          icon: "b2",
        },
        {
          name: "省级课程",
          value: "0",
          icon: "b3",
        },
        {
          name: "院级课程",
          value: "0",
          icon: "b4",
        },
      ],
      JXCQData: [
        {
          name: "国家级成果",
          value: 2,
        },
        {
          name: "省级成果",
          value: 4,
        },
        {
          name: "院级成果",
          value: 10,
        },
      ],
      ZBSData: [
        {
          name: "计算机室",
          value: 0,
        },
        {
          name: "多媒体教室",
          value: 0,
        },
        {
          name: "实验室",
          value: 28,
        },
      ],
      XSSJData: [
        {
          name: "本科生",
          value: 0,
          icon: "eee1QD1.png",
        },
        {
          name: "硕士",
          value: 0,
          icon: "eee1QD2.png",
        },
        {
          name: "博士",
          value: 0,
          icon: "eee1QD3.png",
        },
      ],
      MainData: [
        {
          title: "办学规模",
          ListV: [
            {
              name: "开设专业",
              value: 6,
              DW: "个",
            },
            {
              name: "课程总量",
              value: 400,
              DW: "门",
            },
          ],
        },
        {
          title: "办学资源",
          ListV: [
            {
              name: "图书总量",
              value: 0,
              DW: "人",
            },
            {
              name: "教研设备",
              value: 0,
              DW: "台",
            },
          ],
        },
        {
          title: "师资结构",
          ListV: [
            {
              name: "专任教师",
              value: 89,
              DW: "人",
            },
            {
              name: "兼职教师",
              value: 40,
              DW: "人",
            },
          ],
        },
        {
          title: "学位人数",
          ListV: [
            {
              name: "硕士",
              value: 0,
              DW: "人",
            },
            {
              name: "博士",
              value: 0,
              DW: "人",
            },
          ],
        },
      ],
    };
  },
  methods: {
    formatCurrency(num) {
      return num.toLocaleString("zh-Hans-CN", {
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
      });
    },
    setEcharta() {
      let chartDom = this.$refs.ceshiEchart;
      var chart = this.$echarts.init(chartDom); // 使用全局
      var option = {
        title: {
          text: "师资规模",
          left: 10,
          top: 20,
          textStyle: {
            color: "#FFF",
          },
        },
        grid: {
          width: "10%",
          height: "80%",
          left: "10%",
          top: "10%",
        },
        legend: {
          orient: "vertical",
          itemGap: 20,
          left: 10,
          top: 100,
          textStyle: {
            color: "#FFF",
            fontSize: 12,
          },
          formatter: function (name) {
            // 通过formatter函数进行数据格式化
            var value = 0;
            for (var i = 0; i < option.series[0].data.length; i++) {
              if (option.series[0].data[i].name === name) {
                value = option.series[0].data[i].value;
                break;
              }
            }
            return name + " " + value + "%";
          },
        },
        toolbox: {
          show: true,
          feature: {
            mark: { show: true },
            dataView: { show: true, readOnly: false },
            restore: { show: true },
            saveAsImage: { show: true },
          },
        },
        series: [
          {
            name: "时代大厦收到t",
            type: "pie",
            radius: ["0%", "80%"],
            center: ["60%", "50%"],
            roseType: "area",
            itemStyle: {
              borderRadius: 8,
            },
            label: {
              show: false, // 关闭饼图文字标签
              formatter: "{b}: {c} ({d}%)",
            },
            labelLine: {
              show: false, // 关闭饼图每个扇形中间的连线
            },
            data: [
              { value: 45, name: "助教" },
              { value: 25, name: "讲师" },
              { value: 32, name: "资深讲师" },
              { value: 30, name: "副教授" },
              { value: 28, name: "教授" },
            ],
          },
        ],
      };
      chart.setOption(option);
    },
    JYFXsetEcharta() {
      let chartDom = this.$refs.JSFXEchart;
      var chart = this.$echarts.init(chartDom); // 使用全局
      var option = {
        title: {
          text: "就业分析",
          left: 10,
          top: 20,
          textStyle: {
            color: "#FFF",
          },
        },
        grid: {
          width: "10%",
          height: "80%",
          left: "10%",
          top: "10%",
        },
        toolbox: {
          show: false,
        },
        legend: {
          show: false,
        },

        series: [
          {
            name: "时代大厦收到t",
            type: "pie",
            radius: ["18%", "80%"],
            center: ["50%", "65%"],
            roseType: "area",
            itemStyle: {
              borderRadius: 8,
            },
            label: {
              formatter: function (params) {
                return `${params.name}\n${params.percent}%`;
              },
              color: "#FFF",
              fontSize: 12,
              textStyle: {
                lineHeight: 30,
              },
              rich: {
                name: {
                  lineHeight: 30,
                },
                percent: {
                  lineHeight: 30,
                },
              },
            },
            data: [
              { value: 25, name: "党政机关" },
              { value: 10, name: "企业" },
              { value: 7, name: "自主创业" },
              { value: 10, name: "其他" },
              { value: 35, name: "事业单位" },
              { value: 13, name: "升学/出国（境）" },
            ],
          },
        ],
      };
      chart.setOption(option);
    },
    setEchartzyqk() {
      let chartDom = this.$refs.zdzy;
      var chart = this.$echarts.init(chartDom); // 使用全局
      var option = {
        graphic: {
          type: "text",
          left: 125,
          top: 60,
          style: {
            text: "重点专业",
            fill: "#FFF",
            fontSize: 16,
            fontWeight: "bold",
          },
        },
        legend: {
          show: false,
        },
        toolbox: {
          show: false,
        },
        series: [
          {
            name: "时代大厦收到t",
            type: "pie",
            radius: ["40%", "60%"],
            center: ["40%", "40%"],
            roseType: false,
            itemStyle: {
              borderRadius: 0,
            },
            label: {
              formatter: function (params) {
                return `${params.name} ${params.data.munber}个\n占比：${params.percent}%`;
              },
              color: "#FFF",
            },
            data: [
              { value: 50, name: "省级", munber: "2" },
              { value: 50, name: "国家级", munber: "2" },
            ],
          },
        ],
      };
      chart.setOption(option);
    },
    setEcharttszy() {
      let chartDom = this.$refs.tszy;
      var chart = this.$echarts.init(chartDom); // 使用全局
      var option = {
        grid: {
          width: "10%",
          height: "80%",
          left: "50%",
          top: "10%",
        },
        graphic: {
          type: "text",
          left: "42%",
          top: "50%",
          style: {
            text: "特色专业",
            fill: "#FFF",
            fontSize: 8,
            fontWeight: "bold",
          },
        },
        legend: {
          show: false,
        },
        toolbox: {
          show: false,
        },
        series: [
          {
            name: "时代大厦收到t",
            type: "pie",
            radius: ["60%", "45%"],
            center: ["50%", "55%"],
            roseType: false,
            itemStyle: {
              borderRadius: 0,
            },
            label: {
              formatter: function (params) {
                return `${params.name}${params.data.munber}个\n占比：\n${params.percent}%`;
              },
              color: "#FFF",
              fontSize: 10,
            },
            labelLine: {
              length: 1, // 调整线的长度
            },
            data: [
              { value: 75, name: "省级", munber: "120" },
              { value: 25, name: "国家级", munber: "15" },
            ],
          },
        ],
      };
      chart.setOption(option);
    },
    setEchartjpkc() {
      let chartDom = this.$refs.jpkc;
      var chart = this.$echarts.init(chartDom); // 使用全局
      var option = {
        grid: {
          width: "10%",
          height: "80%",
          left: "50%",
          top: "10%",
        },
        graphic: {
          type: "text",
          left: "42%",
          top: "50%",
          style: {
            text: "精品课程",
            fill: "#FFF",
            fontSize: 8,
            fontWeight: "bold",
          },
        },
        legend: {
          show: false,
        },
        toolbox: {
          show: false,
        },
        series: [
          {
            name: "时代大厦收到t",
            type: "pie",
            radius: ["60%", "45%"],
            center: ["50%", "55%"],
            roseType: false,
            itemStyle: {
              borderRadius: 0,
            },
            label: {
              formatter: function (params) {
                return `${params.name}${params.data.munber}个\n占比：\n${params.percent}%`;
              },
              color: "#FFF",
              fontSize: 10,
            },
            labelLine: {
              length: 1, // 调整线的长度
            },
            data: [
              { value: 75, name: "省级", munber: "120" },
              { value: 25, name: "国家级", munber: "15" },
            ],
          },
        ],
      };
      chart.setOption(option);
    },
  },
};
</script>

<style scoped>
/* .app-container {
  height: 100vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.app-header {
  background-color: #409eff;
  color: #fff;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 20px;
}

.app-logo {
  height: 40px;
  width: 150px;

}

.app-menu {

}

.app-user {

}

.app-sidebar {
  background-color: #f0f2f5;
  width: 200px;
  padding: 20px;
  flex-shrink: 0;
}

.app-content {
  padding: 20px;
  overflow: auto;
  background-color: #fff;
}

.app-footer {
  background-color: #ccc;
  color: #fff;
  text-align: center;
  padding: 20px;
}

.loginC {
  height: 50px;
  width: 50px;
}


.menu-link {
  display: block;
  color: #333;
  padding: 10px 20px;
  text-decoration: none;
  transition: all 0.3s;
}

.menu-link:hover {
  background-color: #f0f2f5;
}

.router-link-active {
  background-color: #409eff;
  color: #fff !important;
} */

/* 间隔======================================================= 下面是可视化*/
.sunSt {
  display: flex;
}

#cgxzhsj {
  border-width: 0px;
  position: absolute;
  left: 713px;
  top: -15px;
  width: 432px;
  height: 0px;
  font-family: "微软雅黑";
  font-weight: 400;
  font-style: normal;
  font-size: 32px;
  color: #66ccff;
}

#u4_div {
  border-width: 0px;
  margin: 10px 0px 0px 0px;
  left: 0px;
  top: 0px;
  width: 403px;
  height: 320px;
  background: inherit;
  background-color: rgba(25, 158, 216, 0);
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(39, 112, 192, 1);
  border-radius: 0px;
  box-shadow: none;
  font-family: "微软雅黑";
  font-weight: 400;
  font-style: normal;
}

.imgone {
  width: -webkit-fill-available;
}

.imgc {
  width: auto;
  height: auto;
  margin: 0px 5px 0px 0px;
}

.boy {
  margin: 0px;
  background-color: rgba(6, 31, 61, 1);
  background-image: none;
  position: static;
  left: auto;
  /* width: 1948px; */
  width: 100%;
  margin-left: 0;
  margin-right: 0;
  text-align: left;
  height: 100%;
  color: white;
}

#ceshiEchart {
  height: 100%;
  width: 100%;
}

#u0_div {
  border-width: 0px;
  margin: 10px 10px 2px 10px;
  left: 0px;
  top: 0px;
  width: 1084px;
  height: 650px;
  background: inherit;
  background-color: rgba(25, 158, 216, 0);
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(39, 112, 192, 1);
  border-radius: 0px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-family: "微软雅黑";
  font-weight: 400;
  font-style: normal;
}

#u140_div {
  border-width: 0px;
  margin: 10px;
  left: 0px;
  top: 0px;
  width: 532px;
  height: 346px;
  background: inherit;
  background-color: rgba(25, 158, 216, 0);
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(39, 112, 192, 1);
  border-radius: 0px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-family: "微软雅黑";
  font-weight: 400;
  font-style: normal;
}

/* .parent > .boy {
  margin: 0;
} */

.u4_divS {
  margin: 0px 10px;
}

#u22_text {
  border-width: 0px;
  left: 0px;
  top: 0px;
  color: white;
  width: 129px;
  word-wrap: break-word;
  font-size: 24px;
}

.top {
  display: flex;
  flex-direction: row;
  height: 50px;
  align-items: center;
}
.sunSt {
  width: 116px;
}

.suBav ul {
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 0;
  padding: 0;
}

.suBav li {
  margin: 0px 0px 0px 10px;
  padding: 0;
}

.ulC {
  width: 207px;
}

.ulC li {
  display: flex;
  flex-direction: row;
  list-style: none;
  margin: 0;
  padding: 0;
}

.ulC a {
  color: #ffffff;
  text-decoration: none;
}

.sunStone {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  margin: 35px 10px;
}

.clsSWEE {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}

.sunStonemagin {
  margin: 0px 0px 0px 80px;
}

.suBav a {
  display: block;
  padding: 10px;
  color: #ffffff;
  text-decoration: none;
  border-right: 1px solid #ddd;
}

.suBav a:last-child {
  border-right: none;
}

.suBav a:hover {
  /* background-color: #ccc; */
  color: #66ccff;
}

.suBav {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 76%;
  text-align: center;
}

.zyqk {
  margin: 20px 20px 0px 20px;
  font-size: 18px;
}

.ce1 {
  font-size: 24px;
}

.kcImg {
  position: relative;
  top: 95px;
  left: 24px;
}

.kcImgt {
  transform: rotate(90deg);
  position: relative;
  top: 71px;
  left: 75px;
}

.ceEE {
  display: flex;
  flex-wrap: wrap;
  margin-top: -50px;
}

.kcStyle {
  /* background-color: rgb(39, 39, 38); */
  margin: 30px 40px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

#JSFXEchart {
  width: 100%;
  height: 100%;
}

.kcStylea {
  display: flex;
  align-items: center;
  margin: 0px 0px 12px 0px;
}

.flex {
  display: flex;
}

.jxcgStyle {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.jxcgStylea {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.cgStyle {
  position: relative;
  top: -34px;
}

.space-around {
  display: flex;
  justify-content: space-around;
  margin-top: 15%;
}

.zyqkc {
  margin: 20px;
}

.zbsStyle {
  margin: 10px;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.zbsSu {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 15%;
}

#u140_divER {
  border-width: 0px;
  margin: 10px 10px 0px 10px;
  left: 0px;
  top: 0px;
  width: 532px;
  height: 318px;
  background: inherit;
  background-color: rgba(25, 158, 216, 0);
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(39, 112, 192, 1);
  border-radius: 0px;
  box-shadow: none;
  font-family: "微软雅黑";
  font-weight: 400;
  font-style: normal;
}

.jyfximgS {
  float: left;
  position: relative;
}

.cel {
  top: 97px;
  left: 174px;
  /* width: 206px;
  height: 200px; */
  transform-origin: center; /* 设置旋转的中心为元素的中心 */
  animation: rotate 15s linear infinite; /* 定义一个旋转动画 */
}

@keyframes rotate {
  from {
    transform: rotate(0deg); /* 开始时角度为0度 */
  }
  to {
    transform: rotate(360deg); /* 结束时角度为360度 */
  }
}

.celsmal {
  top: 122px;
  left: 14px;
  /* width: 142px;
  height: 140px; */
  transform-origin: center; /* 设置旋转的中心为元素的中心 */
  animation: rotate1 15s linear infinite; /* 定义一个旋转动画 */
}

@keyframes rotate1 {
  from {
    transform: rotate(0deg); /* 开始时角度为0度 */
  }
  to {
    transform: rotate(-360deg); /* 结束时角度为360度 */
  }
}

.mainS {
  float: left;
  position: absolute;
  top: 20%;
  width: 300px;
  left: 42%;
}

#u115_div {
  border-width: 0px;
  /* position: absolute;
  left: 0px;
  top: 0px; */
  width: 315px;
  height: 130px;
  margin-right: 300px;
  background: inherit;
  background-color: rgba(25, 158, 216, 0);
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(39, 112, 192, 1);
  border-radius: 0px;
  -moz-box-shadow: none;
  -webkit-box-shadow: none;
  box-shadow: none;
  font-family: "微软雅黑";
  font-weight: 400;
  font-style: normal;
}

#u116_div {
  border-width: 0px;
  width: 140px;
  height: 38px;
  margin: 0px 0px 0px 0px;
  text-align: center;
  position: relative;
  left: 83px;
  background-color: rgba(6, 31, 61, 1);
  box-sizing: border-box;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(6, 31, 61, 1);
  border-radius: 0px;
  box-shadow: none;
  font-family: "微软雅黑";
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  color: #ffffff;
  top: -14px;
}

.bcFont {
  font-family: "微软雅黑";
  font-weight: 400;
  font-style: normal;
  font-size: 16px;
  color: #ffffff;
}

.mainKK {
  height: 100%;
  width: 128%;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  margin-top: 65px;
}

.lianxian {
  float: left;
  position: absolute;
}
</style>