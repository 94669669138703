import Vue from "vue";
import App from "./App.vue";
import "../plugins/element";
import router from "./router";
import MainLayout from "@/components/layouts/MainLayout.vue";
import BlankLayout from "@/components/layouts/BlankLayout.vue";
import * as echarts from "echarts";

Vue.prototype.$echarts = echarts; // 注册全局echarts对象
Vue.config.productionTip = false;

// 本例需要实现组件和布局的映射
const layoutComponentMap = {
  default: MainLayout,
  blank: BlankLayout,
};

// 给每一个路由附上对应的布局组件
router.beforeEach((to, from, next) => {
  to.matched.forEach((match) => {
    if (match.meta.layout) {
      match.components.default = layoutComponentMap[match.meta.layout];
    }
  });
  next();
});

// 将 BlankLayout 注册为全局组件
Vue.component("BlankLayout", BlankLayout);

new Vue({
  el: "#app",
  router,
  render: (h) => h(App),
});

// //全局引入
// import ElementUI from 'element-ui';
// import 'element-ui/lib/theme-chalk/index.css';

// Vue.use(ElementUI)
