import Vue from "vue";
import Router from "vue-router";

Vue.use(Router);

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "Home",
    component: () => import("@/HomeManage/home.vue"),
  },
  {
    path: "/ksh",
    name: "Ksh",
    component: () => import("@/DataShow/ksh.vue"),
  },
  {
    path: "/visualDisplay",
    name: "VisualDisplay",
    component: () => import("@/DataShow/VisualDisplay/index.vue"),
  },
  {
    path: "/user",
    name: "User",
    component: () => import("@/DataShow/user.vue"),
  },
  {
    path: "/role",
    name: "Role",
    component: () => import("@/DataShow/role.vue"),
  },
  {
    path: "/permission",
    name: "Permission",
    component: () => import("@/DataShow/permission.vue"),
  },
];

const router = new Router({
  routes,
});

export default router;
