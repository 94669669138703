<template>
  <div class="main-layout">
    <!-- <header class="main-header">
      <h1>{{ title }}</h1>
    </header>

    <main class="main-content">
      <slot></slot>
    </main>

    <footer class="main-footer">This is footer</footer> -->
  </div>
</template>

<script>
export default {
  name: 'MainLayout',
  props: {
    title: {
      type: String,
      default: 'Main Layout',
    },
  },
};
</script>